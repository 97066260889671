import React, { useEffect, useState } from 'react';
import { PageTitle, SaveButton, saveStatuses, saveTypes } from 'ht-gui';
import { useNavigate, useParams } from 'react-router-dom';

import { Translate } from '../../../hooks/useTranslate';
import tenantsApi from '../../../api/tenantsApi';

import TenantGeneral from './TenantCreate/TenantGeneral';
import Loading from '../../Shared/Loading';
import Error from '../../Shared/Error';

import { verifyObject } from '../../../utilities/verificator';
import { tenantRules } from './TenantCreate/verificationRules';
import { guidRegex } from './TenantCreate';

const TenantEdit = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const navigate = useNavigate();
    const { id } = useParams();

    const [tenant, setTenant] = useState(null);
    const [status, setStatus] = useState({
        saveStatus: saveStatuses.NEUTRAL,
    });
    const [error, setError] = useState(false);
    const [apiError, setApiError] = useState(null);

    /*********************************************************************/
    /* Init
    /*********************************************************************/
    useEffect(() => {
        tenantsApi()
            .get('v1/tenants/tenant/' + id)
            .then((response) => {
                setTenant(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    setApiError({ statusCode: error.response.status, data: typeof error?.response?.data === 'string' ? error?.response?.data : null });
                } else {
                    setApiError({ statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' });
                }
            });
    }, [id]);

    /*********************************************************************/
    /* Save & cancel functions
    /*********************************************************************/

    useEffect(() => {
        if (status.saveStatus === saveStatuses.SAVE_TRIGGERED) {
            setStatus({
                ...status,
                saveStatus: saveStatuses.SAVING,
            });

            // Verify
            if (!verifyObject(tenant, tenantRules)) {
                setStatus({
                    ...status,
                    saveStatus: saveStatuses.ERROR,
                });

                setError(true);
                return;
            }

            // Check if we have valid guids
            if (!guidRegex.test(tenant.companyId)) {
                setStatus({
                    ...status,
                    saveStatus: saveStatuses.ERROR,
                });

                setError(true);
                return;
            }

            if (!guidRegex.test(tenant.ownerGuidId)) {
                setStatus({
                    ...status,
                    saveStatus: saveStatuses.ERROR,
                });

                setError(true);
                return;
            }

            tenantsApi()
                .put(`v1/tenants/tenant/${tenant.id}`, tenant)
                .then((response) => {
                    navigate('/tenants/overview');

                    setStatus({
                        ...status,
                        saveStatus: saveStatuses.SAVED,
                    });
                })
                .catch((error) => {
                    setStatus({
                        ...status,
                        saveStatus: saveStatuses.ERROR,
                    });
                });
        }
    }, [status, navigate, tenant]);

    useEffect(() => {
        if (status.saveStatus === saveStatuses.CANCEL_TRIGGERED) {
            setStatus({
                ...status,
                saveStatus: saveStatuses.CANCELING,
            });

            navigate('/tenants/overview');
        }
    }, [status, navigate]);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/

    const handleSaveStatusChanged = (s) => {
        setStatus({
            ...status,
            saveStatus: s,
        });
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/
    // Loading
    let content = <Loading title={<Translate id='tenants.update.loading' />} description={<Translate id='tenants.update.loadingDesc' />} />;

    // Error
    if (apiError) {
        content = <Error code={apiError.statusCode} message={apiError?.data} />;
    }

    if (tenant) {
        content = (
            <div className='row'>
                <TenantGeneral tenant={tenant} setTenant={setTenant} status={status} error={error} edit />

                <div className='col-12 col-lg-8'>
                    <div className='bg-white box-shadow p-3 mt-3'>
                        <div className='w-50'>
                            <SaveButton showFeedback type={saveTypes.CONTROLLED} status={status.saveStatus} setStatus={handleSaveStatusChanged} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <section id='tenants-create' className='main-scrollable p-4'>
            <PageTitle title={<Translate id='tenants.update.title' />} subTitle={<Translate id='tenants.update.subtitle' />} />

            {content}
        </section>
    );
};

export default TenantEdit;
