import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TitleBar, SaveButton, Input } from 'ht-gui';

import { Translate } from '../../../hooks/useTranslate';
import { routerPrefix } from '../../../routers/MainRouter';

const domainRegex = new RegExp('^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\\.)+[A-Za-z]{2,6}');

const verifyDomain = (domain) => !(!domain || domain.trim() === '' || domain.length > 245 || !domainRegex.test(domain));

const TenantDomainForm = ({tenantDomain, onSave}) => {
    /***********************************************************************
     * State
     ***********************************************************************/
    const [domain, setDomain] = React.useState(tenantDomain.domain);
    const [error, setError] = React.useState(false);
    const navigate = useNavigate();
    const { tenantId } = useParams();

    /***********************************************************************
     * Event handlers
     ***********************************************************************/
    /*
     * Verify the properties and send the updated model to the page
     */
    const handleSaveClicked = (onSuccess, onError) => {
        // Verification
        if (!verifyDomain(domain)) {
            setError(true);
            onError();
            return;
        }

        // Construct the object
        const tenantDomainToSave = {
            domain: domain
        };

        // Send the tenant domain to save or update to the parent component
        onSave(tenantDomainToSave, onSuccess, onError);
    };

    /**
     * Handle when the cancel button gets clicked
     * Navigate back to the previous page
     */
    const handleCancelClicked = React.useCallback(() => {
        navigate(`${routerPrefix}/${tenantId}/domains`);
    }, [navigate, tenantId]);

     /**
     * Handle when the value of 'domain' is changed by the HTInput component
     * Update the value of 'domain' in the state
     */
     const handleDomainChanged = React.useCallback((event, value) => {
        setDomain(event.target.value);
    }, []);

    /***********************************************************************
     * Render
     ***********************************************************************/
    return (
        <>
            <div className='row'>
                <div className='col-12 col-lg-8'>
                    <div className='d-flex flex-column w-100 bg-white box-shadow'>
                        <TitleBar label={<Translate id='tenantDomains.create.general' />} />
                        <div className='d-flex flex-column w-100 bg-white p-3'>
                            <Input
                                id='tenantDomain.create.domain'
                                value={domain}
                                label={<Translate id='tenantDomains.create.domain' />}
                                error={error && !verifyDomain(domain)}
                                required
                                onChange={handleDomainChanged}
                                className='w-100 mb-2'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12 col-lg-8'>
                    <div className='bg-white box-shadow p-3 mt-3'>
                        <div className='w-50'>
                            <SaveButton showFeedback onSave={handleSaveClicked} onCancel={handleCancelClicked} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TenantDomainForm;