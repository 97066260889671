import React from 'react';
import withResize from 'ht-resize';
import { Route, Routes } from 'react-router-dom';
import { useTranslate } from '../hooks/useTranslate';
import { useStore } from 'ht-store';

// Translations
import globalTranslations from '../translations/global.json';

// Components
import TenantRouter from './TenantRouter';

// Css
import '../css/main.scss';

export const routerPrefix = '/tenants';

const MainRouter = (props) => {
    const [auth] = useStore('auth');

    /***********************************************************************
     * Startup
     ***********************************************************************/
    useTranslate(globalTranslations);

    /***********************************************************************
     * Render
     ***********************************************************************/

    let routes = [];

    if (auth && auth.rights && auth.rights.length > 0) {
        if (auth.rights.includes('user.management.access') || auth.rights.includes('*')) {
            routes.push(<Route path={'/*'} element={<TenantRouter />} />);
        }
    }

    // Return the routes
    return (
        <div className='ht-app-tenants'>
            <Routes>
                {/* List */}
                {routes}

                {/* Redirect */}
                {/* <Route path='*' element={<Navigate to={routerPrefix + '/overview'} replace />} /> */}
            </Routes>
        </div>
    );
};

export default withResize()(MainRouter);
