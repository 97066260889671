import { useNavigate, useParams } from 'react-router-dom';
import { PageTitle } from 'ht-gui';

import { Translate } from '../../../hooks/useTranslate';

import tenantsApi from '../../../api/tenantsApi';
import TenantDomainForm from "./TenantDomainForm";

const defaultTenantDomain = {
    domain: ''
};

/**
 * Create a new Tenant domain
 */
const TenantDomainCreate = () => {
    /*********************************************************************
     * State
     *********************************************************************/
    const { tenantId } = useParams();
    const navigate = useNavigate();

    /*********************************************************************
     * Event handlers
     *********************************************************************/
    const handleSaveClicked = (tenantDomain, onSuccess, onError) => {
        tenantsApi()
            .post(`v1/tenants/tenant/${tenantId}/domain`, tenantDomain)
            .then((response) => {
                navigate(`/tenants/${tenantId}/domains`);
                onSuccess();
            })
            .catch((error) => {
                onError(error);
            });
    }

    const handleLinkClicked = (event, route) => {
        event.preventDefault();
        navigate(route);
    }

    /*********************************************************************
     * Render
     *********************************************************************/
    const breadCrumbs = [{
        route: '/',
        label: 'Tenants'
    },
    {
        route: null,
        label: tenantId
    },
    {
        route: `/tenants/${tenantId}/domains`,
        label: 'Domains'
    },
    {
        route: null,
        label: 'Create'
    }]

    return (
        <section id='tenant-domain-create' className='main-scrollable p-4'>
            <PageTitle title={<Translate id='tenantDomains.create.title' />} subTitle={<Translate id='tenantDomains.create.subtitle' />} breadcrumbs={breadCrumbs} onLinkClicked={handleLinkClicked}/>
            <TenantDomainForm tenantDomain={defaultTenantDomain} onSave={handleSaveClicked} />            
        </section>
    );
}

export default TenantDomainCreate;