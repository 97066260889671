import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageTitle } from 'ht-gui';

import { Translate } from '../../../hooks/useTranslate';

import tenantsApi from '../../../api/tenantsApi';
import TenantDomainForm from "./TenantDomainForm";

import Loading from '../../Shared/Loading';

const TenantDomainUpdate = () => {
    /*********************************************************************
     * State
     *********************************************************************/
    const [tenantDomain, setTenantDomain] = useState(null);
    const { tenantId, tenantDomainId } = useParams();
    const navigate = useNavigate();

    /*********************************************************************
     * Startup
     *********************************************************************/
    useEffect(() => {
        tenantsApi().get(`v1/tenants/tenant/${tenantId}/domain/${tenantDomainId}`).then(response => {
            if(response && response.data) {
                setTenantDomain(response.data);
            }
        })
    }, [tenantId, tenantDomainId])

    /*********************************************************************
     * Event handlers
     *********************************************************************/
    const handleSaveClicked = (updatedTenantDomain, onSuccess, onError) => {
        const tenantDomainToSave = {...updatedTenantDomain, id: tenantDomain.id};

        tenantsApi()
            .put(`v1/tenants/tenant/${tenantId}/domain/${tenantDomain.id}`, tenantDomainToSave)
            .then((response) => {
                navigate(`/tenants/${tenantId}/domains`);
                onSuccess();
            })
            .catch((error) => {
                onError(error);
            });
    }

    const handleLinkClicked = (event, link) => {
        event.preventDefault();
        navigate(link);
    }


    /*********************************************************************/
    /* Render
    /*********************************************************************/
    let content = null;

    // Loading
    if (!tenantDomain) {
        content = <Loading title={<Translate id='tenantDomains.overview.loading' />} description={<Translate id='tenantDomains.overview.loadingDesc' />} />;
    }
    else {
        content = <TenantDomainForm tenantDomain={tenantDomain} onSave={handleSaveClicked} /> 
    }

    const breadCrumbs = [{
        route: '/',
        label: 'Tenants'
    },
    {
        route: null,
        label: tenantId
    },
    {
        route: `/tenants/${tenantId}/domains`,
        label: 'Domains'
    },
    {
        route: null,
        label: tenantDomainId
    },
    {
        route: null,
        label: 'Update'
    }]

    return (
        <section id='tenant-domain-create' className='main-scrollable p-4'>
            <PageTitle title={<Translate id='tenantDomains.create.title' />} subTitle={<Translate id='tenantDomains.create.subtitle' />} breadcrumbs={breadCrumbs} onLinkClicked={handleLinkClicked} />
            {content}           
        </section>
    );

}

export default TenantDomainUpdate;