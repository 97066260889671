import React from 'react';
import { Translate, useTranslate } from '../../../hooks/useTranslate';
import { routerPrefix } from '../../../routers/MainRouter';

import sidebarLeftTranslations from '../../../translations/layout/sidebarLeft.json';

const SidebarLeft = ({ first, container, onClick }) => {
    // Add translation
    useTranslate(sidebarLeftTranslations);

    /********************************/
    /* Render
    /********************************/
    // Define config
    let config = [
        {
            link: routerPrefix,
            label: <Translate id='tenants.sidebarLeft.overview' />,
            icon: 'fal fa-house-user',
            hoverIcon: 'fas fa-house-user',
        },
    ];

    let SidebarItemsContainer = container;
    return <SidebarItemsContainer first={first} config={config} onClick={onClick} title={<Translate id='tenants.sidebarLeft.title' />} />;
};

export default SidebarLeft;
