import React, { useEffect } from 'react';

import { useTranslations, useTranslate } from '../hooks/useTranslate';

// Import components
import Layout from './Layout/Layout';

// Import translation file
import globalTranslations from '../translations/global.json';

const App = () => {
    const [setLanguages, setCurrentLanguage] = useTranslations();
    useTranslate(globalTranslations);

    useEffect(() => {
        setLanguages(['en', 'nl']);

        const userLang = navigator.language || navigator.userLanguage;
        if (userLang.includes('en')) {
            setCurrentLanguage('en');
        } else {
            setCurrentLanguage('nl');
        }
    }, [setLanguages, setCurrentLanguage]);

    return <Layout />;
};

export default App;
