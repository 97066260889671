import React, { useCallback, useEffect, useState } from 'react';
import { TitleBar, ManageTable, NoContent, ManageTableActions } from 'ht-gui';
import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import Error from '../../Shared/Error';
import Loading from '../../Shared/Loading';
import CheckDisplay from '../../Shared/Managetable/CheckDisplay';

import tenantsApi from '../../../api/tenantsApi';
import { Translate } from '../../../hooks/useTranslate';

import { routerPrefix } from '../../../routers/MainRouter';
import DeleteModal from '../../Shared/DeleteModal';

const TenantsOverview = (props) => {
    /*********************************************************************/
    /* State
    /*********************************************************************/
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [deletePopup, setDeletePopup] = useState(null);

    const navigate = useNavigate();

    /*********************************************************************/
    /* Init
    /*********************************************************************/
    useEffect(() => {
        tenantsApi()
        .get('v1/tenants/tenant')
        .then((response) => {
            setData(response.data);
        })
        .catch((error) => {
            if (error.response) {
                setError({ statusCode: error.response.status, data: typeof error?.response?.data === 'string' ? error?.response?.data : null });
            } else {
                setError({ statusCode: 503, data: 'Your dail-up connection to the cloud has been severed' });
            }
        });
    }, []);

    /*********************************************************************/
    /* Functions
    /*********************************************************************/

    const handleCreateClicked = (e) => {
        e.preventDefault();
        navigate(routerPrefix + '/create');
    };

    const handleEditClicked = (data) => {
        navigate(`${routerPrefix}/update/${data.id}`);
    };

    const handleEditDomainsClicked = (data) => {
        navigate(`${routerPrefix}/${data.id}/domains`);
    }

    const handleDeleteClicked = (data) => {
        setDeletePopup({ object: data });
    };

    const handleDoubleClicked = (e, data) => {
        e.preventDefault();
        navigate(`${routerPrefix}/update/${data.id}`);
    };

    const handleConfirmDeleteClicked = (object, onSuccess, onError) => {
        tenantsApi()
            .delete(`v1/tenants/tenant/${object.id}`)
            .then((response) => {
                let currentTenants = [...data];
                let index = currentTenants.findIndex((t) => t.id === object.id);

                if (index === -1) {
                    return;
                }

                currentTenants.splice(index, 1);

                setData(currentTenants);
                onSuccess();
            })
            .catch((error) => {
                onError();
            });
    };

    const handleTenantDeleted = (object) => {
        setDeletePopup(null);
    };

    /*********************************************************************/
    /* Managetable config
    /*********************************************************************/

    const componentsAfter = (
        <Button variant='contained' className='bg-success text-white rounded-0 ml-1' onClick={handleCreateClicked}>
            <Translate id='tenants.overview.createNew' />
        </Button>
    );

    const config = {
        columns: {
            id: {
                label: <Translate id='tenants.overview.id' />,
            },
            name: {
                label: <Translate id='tenants.overview.name' />,
            },
            company: {
                label: <Translate id='debtorsOverview.createdTime' />,
            },
            owner: {
                label: <Translate id='debtorsOverview.lastUpdatedTime' />,
            },
            isActive: {
                label: <Translate id='tenants.overview.isActive' />,
                component: CheckDisplay,
                props: {
                    checked: 'isActive',
                },
            },
            actions: {
                label: 'Actions',
                component: ManageTableActions,
                props: {
                    actions: [
                        {
                            icon: 'fal fa-pencil',
                            hoverIcon: 'fas fa-pencil',
                            onClick: handleEditClicked,
                        },
                        {
                            icon: 'fal fa-globe',
                            hoverIcon: 'fas fa-globe',
                            onClick: handleEditDomainsClicked
                        },
                        {
                            icon: 'fal fa-trash',
                            hoverIcon: 'fas fa-trash',
                            onClick: handleDeleteClicked,
                        }                        
                    ],
                },
            },
        },
        search: {
            componentsAfter: componentsAfter,
        },
        onDoubleClick: handleDoubleClicked,
        data: data,
    };

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    // Error
    if (error) {
        return <Error code={error.statusCode} message={error?.data} />;
    }

    // Loading
    if (!data) {
        return <Loading title={<Translate id='tenants.overview.loading' />} description={<Translate id='tenants.overview.loadingDesc' />} />;
    }

    // No entries
    if (data && data.length === 0) {
        return (
            <NoContent
                title={<Translate id='tenants.overview.noEntries' />}
                description={<Translate id='tenants.overview.noEntriesDesc' />}
                backgroundColour='bg-dark-blue'
                icon='fas fa-shelves-empty fa-7x'
            />
        );
    }

    let deleteModalContent = null;
    if (deletePopup) {
        let description = (
            <>
                <Translate id='tenants.delete.areYouSureDelete' />
                <div>
                    <div>{deletePopup.object.name}</div>
                </div>
            </>
        );
        deleteModalContent = (
            <DeleteModal
                object={deletePopup.object}
                onConfirmClicked={handleConfirmDeleteClicked}
                title={<Translate id='tenants.delete.title' />}
                description={description}
                failed={<Translate id='tenants.delete.failed' />}
                titleBarColor='bg-danger'
                confirmClasses={['bg-danger', 'text-white']}
                onClose={() => setDeletePopup(null)}
                onDeleted={handleTenantDeleted}
            />
        );
    }

    return (
        <>
            {deleteModalContent}

            <div className='box-shadow bg-white'>
                <TitleBar label={<Translate id='tenants.overview.title' />} />
            </div>

            <ManageTable {...config} />
        </>
    );
};

export default TenantsOverview;
