import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useTranslate } from '../hooks/useTranslate';

// Translations
import tenantTranslations from '../translations/general/tenants.json';
import tenantDomainsTranslations from '../translations/general/tenantDomains.json';

// Components
import Tenants from '../pages/Tenants';
import TenantCreate from '../components/General/Tenants/TenantCreate';
import TenantEdit from '../components/General/Tenants/TenantEdit';

// Css
import '../css/main.scss';
import TenantDomains from '../pages/TenantDomains';
import TenantDomainUpdate from '../components/General/TenantDomains/TenantDomainUpdate';
import TenantDomainCreate from '../components/General/TenantDomains/TenantDomainCreate';

export const routerPrefix = '/tenants';

const TenantRouter = (props) => {
    /***********************************************************************
     * Startup
     ***********************************************************************/
    useTranslate(tenantTranslations);
    useTranslate(tenantDomainsTranslations);

    /***********************************************************************
     * Render
     ***********************************************************************/

    // Return the routes
    return (
        <div className='ht-app-tenants'>
            <Routes>
                {/* Display of the tenant domains */}
                <Route path=':tenantId/domains' element={<TenantDomains />} />

                {/* Update a single tenant domain */}
                <Route path=':tenantId/domains/:tenantDomainId/update' element={<TenantDomainUpdate />} />

                {/* Create a new tenant domain */}
                <Route path=':tenantId/domains/create' element={<TenantDomainCreate />} />

                {/* Update */}
                <Route path='update/:id/*' element={<TenantEdit />} />

                {/* Create */}
                <Route path='create/*' element={<TenantCreate />} />

                {/* Overview */}
                <Route path='overview/*' element={<Tenants />} />

                {/* Redirect */}
                <Route path='*' element={<Navigate to='overview' />} />
            </Routes>
        </div>
    );
};

export default TenantRouter;
