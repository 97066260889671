import React from 'react';
import { Input, TitleBar, saveStatuses, Switch } from 'ht-gui';

import { Translate } from '../../../../hooks/useTranslate';
import { verifyProperty, verifyObject } from '../../../../utilities/utility';

import { tenantRules } from './verificationRules';
import { guidRegex } from '../TenantCreate';

import Property from '../../../Shared/Property';

const TenantGeneral = (props) => {
    const { tenant, setTenant, status, error, edit } = props;

    /*********************************************************************/
    /* Render
    /*********************************************************************/

    let id = null;

    if (edit && tenant.id) {
        id = <Property label={<Translate id='tenants.create.id' />} value={tenant.id} />;
    }

    return (
        <div className='col-12 col-lg-8'>
            <div className='bg-white box-shadow'>
                <TitleBar label={<Translate id='tenants.create.general' />} />
                <div className='p-3'>
                    <div className='row'>
                        <div className="col-12">
                            {id}
                            <Input
                                id='tenant.create.name'
                                value={tenant.name}
                                label={<Translate id='tenants.create.name' />}
                                error={error && !verifyProperty(tenant.name, tenantRules.name)}
                                required
                                onChange={(e) => setTenant({ ...tenant, name: e.target.value })}
                                className='w-100 mb-2'
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />

                            <Switch
                                label={<Translate id='tenants.create.isActive' />}
                                id='tenant.create.isActive'
                                checked={tenant.isActive}
                                onChange={(e) => setTenant({ ...tenant, isActive: e.target.checked })}
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />

                            <Input
                                id='tenant.create.companyId'
                                value={tenant.companyId}
                                label={<Translate id='tenants.create.companyId' />}
                                error={error && (!verifyProperty(tenant.companyId, tenantRules.companyId) || !guidRegex.test(tenant.companyId))}
                                required
                                onChange={(e) => setTenant({ ...tenant, companyId: e.target.value })}
                                className='w-100 my-3'
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />

                            <Input
                                id='tenant.create.ownerId'
                                value={tenant.ownerGuidId}
                                label={<Translate id='tenants.create.ownerId' />}
                                error={error && (!verifyProperty(tenant.ownerGuidId, tenantRules.ownerGuidId) || !guidRegex.test(tenant.ownerGuidId))}
                                required
                                onChange={(e) => setTenant({ ...tenant, ownerGuidId: e.target.value })}
                                className='w-100'
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />
                            <Switch
                                label={<Translate id='tenants.create.isAutoUserCreateAllowed' />}
                                id='tenant.create.isAutoUserCreateAllowed'
                                checked={tenant.isAutoUserCreateAllowed}
                                onChange={(e) => setTenant({ ...tenant, isAutoUserCreateAllowed: e.target.checked })}
                                disabled={status.saveStatus === saveStatuses.SAVING || status.saveStatus === saveStatuses.CANCELING}
                            />
                        </div>
                    </div>

                    {/* Error */}
                    {error && !verifyObject(tenant, tenantRules) ? (
                        <div className='row'>
                            <div className='col-6 '>
                                <div className='alert alert-danger rounded-0 mb-0 mt-3'>
                                    <Translate id='tenants.create.error' />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default TenantGeneral;
