import { useCallback, useEffect, useState } from 'react';

let _currentLanguage = 'nl';
let languages = ['en', 'nl'];
let translations = {};

function resolve(path, obj) {
    return path.split('.').reduce(function (prev, curr) {
        return prev ? prev[curr] : null;
    }, obj);
}

export const useTranslate = (translationFile) => {
    const [, , getCurrentLanguage] = useTranslations();
    const currentLanguage = getCurrentLanguage();

    const updateKey = useCallback((translationsSubSet, translationsFileSubSet, base) => {
        let data = { ...translationsSubSet };

        Object.keys(translationsFileSubSet).forEach((key) => {
            if (Object.keys(data).indexOf(key) === -1) {
                // Add
                data = {
                    ...data,
                    [key]: translationsFileSubSet[key],
                };
            } else if (typeof translationsFileSubSet[key] === 'object' && !Array.isArray(translationsFileSubSet[key])) {
                // Update / add
                data = {
                    ...data,
                    [key]: updateKey(data[key], translationsFileSubSet[key], base ? `${base}.${key}` : key),
                };
            } else {
                // Skip
                console.warn(`Found duplicate of key: ${base}.${key}`);
            }
        });

        return data;
    }, []);

    useEffect(() => {
        // Add the translationfile when the index doesn't already exist
        if (translationFile !== null && translationFile !== undefined && typeof translationFile === 'object') {
            translations = {
                ...translations,
                ...updateKey(translations, translationFile),
            };
        }
    }, [updateKey, translationFile]);

    const translate = (key, data) => {
        // Get the index from the current language
        const index = languages.indexOf(currentLanguage);

        // Get the translation for the key and the current language
        let translation = resolve(key, translations)?.[index];

        if (translation === null || translation === undefined) {
            return 'No translate found for key ' + key;
        }

        if (data) {
            Object.keys(data).forEach((key) => {
                if (translation.indexOf('${' + key + '}') !== -1) {
                    if (data[key] !== null && data[key] !== undefined) {
                        translation = translation.replaceAll('${' + key + '}', data[key]);
                    } else {
                        translation = translation.replaceAll('${' + key + '}', '');
                    }
                }
            });
        }

        // remove all variables that couldn't be replaced
        translation = translation.replaceAll(/\${\w*}/g, '');

        return translation;
    };

    return translate;
};

export const useTranslations = () => {
    const [currentLanguage, setCurrentLanguageState] = useState(_currentLanguage);

    const setLanguages = (newLanguages) => {
        languages = [...newLanguages];
    };

    const setCurrentLanguage = (newCurrentLanguage) => {
        _currentLanguage = newCurrentLanguage;
        setCurrentLanguageState(newCurrentLanguage);
    };

    const getCurrentLanguage = () => {
        return currentLanguage;
    };

    return [setLanguages, setCurrentLanguage, getCurrentLanguage];
};

export const Translate = (props) => {
    // Use our hook to fetch the translation
    const translate = useTranslate();

    // If no id is passed we can't render that property
    if (!props.id) {
        return 'Please pass a translation id';
    }
    // Create a translation for the id
    return translate(props.id, props.data);
};
