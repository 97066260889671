import React from 'react';
import { PageTitle } from 'ht-gui';
import { useNavigate } from 'react-router-dom';

import { Translate } from '../hooks/useTranslate';

import TenantsOverview from '../components/General/Tenants/TenantsOverview';

const Tenants = (props) => {
    /*********************************************************************
     * State
     *********************************************************************/
    const navigate = useNavigate();

    /*********************************************************************
     * Event handlers
     *********************************************************************/
    const handleLinkClicked = (event, route) => {
        event.preventDefault();
        navigate(route);
    }

    /*********************************************************************
     * Render
     *********************************************************************/
    const breadCrumbs = [{
        route: null,
        label: 'Tenants'
    }];

    return (
        <>
            <section id='tenants-overview' className='main-scrollable p-4'>
                <PageTitle title={<Translate id='tenants.title' />} subTitle={<Translate id='tenants.subtitle' />} breadcrumbs={breadCrumbs} onLinkClicked={handleLinkClicked} />
                <div className=''>
                    <TenantsOverview />
                </div>
            </section>
        </>
    );
};

export default Tenants;
