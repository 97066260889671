import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { PageTitle } from 'ht-gui';

import { Translate } from '../hooks/useTranslate';
import { routerPrefix } from '../routers/MainRouter';

import TenantDomainsOverview from '../components/General/TenantDomains/TenantDomainsOverview';


const TenantDomains = () => {
    /*********************************************************************
     * State
     *********************************************************************/
    const {tenantId} = useParams();
    const navigate = useNavigate();

    /*********************************************************************
     * Event handlers
     *********************************************************************/
    const handleLinkClicked = (event, route) => {
        event.preventDefault();
        navigate(route);
    }

    /*********************************************************************
     * Render
     *********************************************************************/
    const breadCrumbs = [{
        route: `${routerPrefix}/overview`,
        label: 'Tenants'
    },
    {
        route: null,
        label: tenantId
    },
    {
        route: null,
        label: 'Domains'
    }];

    return (
        <>
            <section id='tenants-domains-overview' className='main-scrollable p-4'>
                <PageTitle title={<Translate id='tenantDomains.title' />} subTitle={<Translate id='tenantDomains.subtitle' />} breadcrumbs={breadCrumbs} onLinkClicked={handleLinkClicked}  />
                <div className=''>
                    <TenantDomainsOverview />
                </div>
            </section>
        </>
    );
}

export default TenantDomains;