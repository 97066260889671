import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import { getClasses } from 'ht-resize';
import { SaveButton, TitleBar } from 'ht-gui';

import { Translate } from '../../hooks/useTranslate';

const DeleteModal = ({ object, api, link, title, description, failed, confirmLabel, titleBarColor, confirmClasses, confirmingLabel, onClose, onConfirmClicked, onDeleted }) => {
    const [error, setError] = useState(false);

    /***********************************************************************
     * Event handlers
     ***********************************************************************/
    const handleDeleteClicked = (onSuccess, onError) => {
        setError(false);

        if (onConfirmClicked) {
            onConfirmClicked(
                object,
                () => {
                    onSuccess();
                    onDeleted(object);
                },
                () => {
                    onError();
                    setError(true);
                }
            );
        } else {
            api.delete(link + '/' + object.id)
                .then(() => {
                    onSuccess();
                    onDeleted(object);
                })
                .catch((error) => {
                    console.log('Error: ', error);
                    onError();
                    setError(true);
                });
        }
    };

    /***********************************************************************
     * Render
     ***********************************************************************/
    if (!titleBarColor) {
        titleBarColor = 'bg-primary';
    }

    let saveButtonClasses = [];
    if (!confirmClasses) {
        saveButtonClasses = ['bg-primary', 'text-white'];
    } else {
        if (Array.isArray(confirmClasses)) {
            saveButtonClasses = [...confirmClasses];
        } else {
            saveButtonClasses = [confirmClasses];
        }
    }

    return (
        <Modal open onClose={onClose} aria-labelledby='destination-delete' aria-describedby='Destination delete popup' className='d-flex align-items-center justify-content-center'>
            <div className={getClasses({ shared: 'd-flex flex-column bg-white outline-0', xs: 'w-75', md: 'w-50', lg: 'w-25' })}>
                {/* Title */}
                <TitleBar
                    color={titleBarColor}
                    label={title}
                    componentsAfter={
                        <div className='ml-auto d-flex align-items-center justify-content-center clickable' onClick={onClose}>
                            <i className='fas fa-times'></i>
                        </div>
                    }
                />

                {/* Description */}
                <span className='font-large p-3'>{description}</span>

                {error ? <div className='alert alert-danger rounded-0 mx-3'>{failed}</div> : null}

                <div className='ml-auto mr-3 mb-3'>
                    <SaveButton
                        showFeedback
                        hideCancel
                        onSave={handleDeleteClicked}
                        saveContent={confirmLabel ? confirmLabel : <Translate id='tenants.confirmDelete' />}
                        saveClasses={saveButtonClasses.join(' ')}
                        savingContent={confirmingLabel ? confirmingLabel : <Translate id='tenants.deleting' />}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default DeleteModal;
