import React from 'react';
import { NoContent } from 'ht-gui';

const Error = ({ code, message, className }) => {
    let iconClassNames = ['fas', 'fa-7x'];

    switch (code) {
        case 503:
            iconClassNames.push('fa-cloud-slash');
            break;

        default:
            iconClassNames.push('fa-bug');
            break;
    }

    return (
        <div className={className}>
            <NoContent title={code} description={message} icon={iconClassNames.join(' ')} backgroundColour='bg-danger' />
        </div>
    );
};

export default Error;
